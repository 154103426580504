<template>
  <div class="bg-white shadow-xl border-l-2 cust_card border-gray-100"  style="position: fixed;height: 100%;width: 100%;min-width: 80px;z-index: 100;top: 0;overflow-x: hidden;transition: 0.5s;border-radius: 0px !important;right:0;">
    <div class="bottom_space rounded px-5 py-2 bg-primary text-white heading-2 font-bold flex items-center justify-between">
      <div>
        <p class="overFlowPara">{{title}}<span v-if="entityName !== undefined && entityName !== ''">: {{entityName}}</span></p>
      </div>
      <div class="flex gap-2">
        <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="emitData(false)"/>
        <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="uploadImage"/>
      </div>
    </div>
      <div>
        <input
        type="file"
        @change="imageToCropUpload"
        @input="imageToCropUpload"
        ref="file12"
        id="file12"
        style="display: none"
      />
      <button class="hover:bg-blue-100 focus:bg-blue-100 px-3 py-2 text-blue-500 transition delay-100 heading-4" v-if="showSelectionButton" color="primary" depressed rounded @click="$refs.file12.click()">Select New Image</button>
      </div>
      <div wrap>
      <div :style="innerWidth > 800 ? `display: flex` : ''" v-if="showClipper">
        <div class="ma-2 imageDiv" :style="`width:${innerWidth > 500 ? innerWidth > 800 ? 800 : innerWidth : innerWidth}px`">
          <div wrap class="p-2 flex justify-between">
            <div class="heading-2">
              Image
            </div>
            <div  class="text-right">
              <button @click="$refs.file12.click();showResult = false" color="primary" class="hover:bg-blue-100 focus:bg-blue-600 px-3 py-1 text-blue-500 transition delay-100" style="letter-spacing:1px" text>
                Change Image
              </button>
            </div>
          </div>
          <div class="divider"></div>
          <clipperFixed
            class="my-clipper"
            :bg-color="bgColor"
            ref="clipper"
            :ratio="ratio"
            :crossorigin="'anonymous'"
            style="height:400px"
            :src="imageToCrop"
            :rotate="rotateDegree"
            :area="areaVal"
            preview="my-preview">
              <div class="placeholder" slot="placeholder">No image</div>
          </clipperFixed>
          <div flat class="py-3">
            <div wrap class="flex items-center justify-between">
              <div xl8 md8 sm6 xs6 lg8 class="pa-2 displayFlex">
                <div @click="rotateDegree -= 10" class=" cursor-pointer">
                  <i class="mx-2 fas fa-undo"></i>
                </div>
                <div style="width: 50px">
                  <input
                   v-model="rotateDegree" autocomplete="new-password" type="" maxlength="100"  @blur="VerifyEmpEmail()"
                   class="outline-none border border-gray-500 text-gray-900 text-sm rounded  focus:border-gray-800 block w-full pl-2 p-2.5 h-12 heading-4" 
                   placeholder="Login Password">
                </div>
                <div @click="rotateDegree += 10"  class=" cursor-pointer">
                  <i class="mx-2 fas fa-redo"></i>
                </div>
              </div>
              <div></div>
              <div xl4 lg4 md4 sm6 xs6 class="text-right displayFlex pr-2" style="justify-content: flex-end;align-items: stretch;">
                <div class="p-2 pb-0">
                  <div style="background-color:black" @click="bgColor = '#000000'" class="colorDiv"></div>
                  <span v-if="bgColor === '#000000'"><i small color="" class="mx-1 h-5 w-5 fas fa-check text-primary"></i></span>
                </div>
                <div class="p-2 pb-0">
                  <div style="background-color:white" @click="bgColor = '#ffffff'" class="colorDiv"></div>
                  <span v-if="bgColor === '#ffffff'"><i small color="" class="mx-1 h-5 w-5 fas fa-check text-primary"></i></span>
                </div>
                <div class="ml-3" style="align-self: center;">
                  <span @click="zoomIn">
                    <i class="mx-1 fas fa-search-plus text-text2 h-5 w-5" small ></i>
                  </span>
                  /
                  <span @click="zoomOut">
                    <i class="mx-1 fas fa-search-minus text-text2 h-5 w-5" small></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="m-3 my-0 previewDiv" :style="`width:${innerWidth > 800 ? 350 : innerwidth < 400 ? innerWidth : 300}px`">
            <div class="p-2">
              <h3 class="heading-2">Preview</h3>
            </div>
            <div class="divider"></div>
            <clipperPreview name="my-preview" class="my-clipper" :crossorigin="'anonymous'">
                <div class="placeholder" slot="placeholder">preview area</div>
            </clipperPreview>
        </div>
      </div>
    </div>
    </div>
</template>
<script>
import 'vuejs-clipper/dist/vuejs-clipper.css'
// import VuejsClipper from 'vuejs-clipper/dist/vuejs-clipper.umd.min'
// import { clipperBasic } from 'vuejs-clipper'
import {  clipperPreview, clipperFixed  } from 'vuejs-clipper/dist/vuejs-clipper.umd'
import Button from '@/View/components/globalButton.vue'

export default {
  components: {
    // clipperRange,
    clipperFixed,
    clipperPreview,
    Button
  },
  data () {
    return {
      rotateDegree: 0,
      resultURL: '',
      showClipper: false,
      bgColor: '#ffffff',
      innerWidth: 0,
      imageToCrop: '',
      areaVal: parseInt(24),
      zoomScale: 1,
      showSelectionButton: true
    }
  },
  props: ['empObj', 'title', 'ratio', 'entityName'],
  mounted () {
    this.$refs.file12.click()
  },
  watch: {
    rotateDegree: {
      handler () {
        if (this.rotateDegree === 360) {
          this.rotateDegree = 0
        }
      }
    }
  },
  created () {
    this.innerWidth = window.innerWidth - 20
  },
  methods: {
    getResult: function () {
      this.showResult = true
      this.showClipper = false
      // canvas->image
    },
    zoomIn () {
      this.zoomScale += 0.1
      this.$refs.clipper.setWH$.next(this.zoomScale)
    },
    zoomOut () {
      this.zoomScale -= this.zoomScale <= 0.15 ? 0 : 0.1
      this.$refs.clipper.setWH$.next(this.zoomScale)
      console.log(this.zoomScale)
    },
    dataURItoBlob () {
      return new Promise((resolve) => {
        const canvas = this.$refs.clipper.clip()
        // call component's clip method
        this.resultURL = canvas.toDataURL('image/jpeg', 1)
        let dataURI = this.resultURL
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString = ''
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
          byteString = atob(dataURI.split(',')[1])
        } else {
          byteString = unescape(dataURI.split(',')[1])
        }
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length)
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i)
        }
        resolve(new Blob([ia], {type: mimeString}))
      })
    },
    emitData (isUpdated, file) {
      let url = this.resultURL
      this.$root.$emit('closeProfileDialog', {
        isUpdated: isUpdated,
        imageToCrop: url,
        file: file
      })
    },
    uploadImage () {
      this.dataURItoBlob().then(file => {
        this.emitData(true, file)
      })
    },
    imageToCropUpload (event) {
      console.log('event', event.target.files[0])
      this.showSelectionButton = false
      this.showClipper = true
      let file = event.target.files[0]
      this.imageToCrop = URL.createObjectURL(file)
    }
  }
}
</script>
<style scoped>
.placeholder {
  text-align: center;
  padding: 20px;
  background-color: lightgray;
}
.optionsCard{
  position: absolute;
  bottom:122px;
  right:5px;
}
.input-437 .v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted {
  padding:0 !important;
}
.colorDiv {
  width: 30px;
  height: 20px;
  border: solid 1px lightgray;
}
.displayFlex {
  display: flex;
  align-items: center;
}
.previewDiv {
  border: solid 1px lightgray;
}
.imageDiv {
  /* height: 500px; */
  position: relative;
  border: solid 1px lightgray;
}
.resultDiv {
  width: 350px;
}
</style>