<template>
<svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" :fill="color" viewBox="0 0 16 16" ><path fill-rule="gray" clip-rule="evenodd" d="M8.024 5.928l-4.357 4.357-.62-.618L7.716 5h.618L13 9.667l-.619.618-4.357-4.357z" :fill="color"/></svg>
</template>
<script>
export default {
  name: 'upAngleSvg', // vue component name
  props: {
    color: {
      type: String,
      default: 'white'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>
